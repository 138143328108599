import React, { ReactNode, ReactElement } from "react"
import LayoutWrapper from "./LayoutWrapper"
import SEO from "./SEO"

interface Props {
  children: ReactNode
  pageContext: any
}

function HomepageLayout({ children, pageContext }: Props): ReactElement {
  const { title } = pageContext.frontmatter

  return (
    <LayoutWrapper>
      <SEO title={title} />
      <section className="flex-col pt-4 mt-8 mb-4 bg-brand md:ml-10 md:flex">
        <h1
          style={{
            lineHeight: 0.7,
            textShadow: "0px 0.25px 2px #2a4365",
          }}
          className="pr-4 font-serif text-xl font-bold md:text-4xl text-page"
        >
          {title}
        </h1>
      </section>

      <div className="flex flex-col md:flex-row">
        <article className="w-auto md:w-2/3 md:pl-10">{children}</article>
      </div>
    </LayoutWrapper>
  )
}

export default HomepageLayout
